import jwtConfig from '@iso/config/jwt.config';
// import { createBrowserHistory } from 'history';
// const history = createBrowserHistory();
const customHeader = (isFile) => (
  isFile ?
    {
      Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
    } :
    {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
    }
);


const base = (method, url, data = {}, endpointUrl = null, isFile = false) => {
  var urlToCall = endpointUrl ?? jwtConfig.fetchUrl;
  return fetch(`${urlToCall}${url}`, {
    method,
    headers: customHeader(isFile),
    body: isFile ? data : (method === "get" ? null : JSON.stringify(data))
  })
    .then(response => {
      if (response.status === 200 || response.status === 400) {
        return response.json();
      }
      else if (response.status === 403) {
        return { success: false, status: response.status, message: "You are not authorized." };
      }
      else if (response.status === 401) {
        if (response.headers.has("token-expired")) {
          return RefreshToken(method, url, data, endpointUrl, isFile);
        }
        //In this case just not authorized to requested url!
        return { success: false, status: response.status, message: "You are not authorized." };
      }
      else return { success: false, status: response.status, message: "Something went wrong try again later or Please sign in again!" };
    });
  // .then(res => {
  //   ;
  //   return res;
  // })
  // .catch(error => error);
};


const SuperFetch = {};
['get', 'post', 'put', 'delete'].forEach(method => {
  SuperFetch[method] = base.bind(null, method);
});

const RefreshToken = (method, url, data, endpointUrl, isFile) => {
  return SuperFetch.post("/refreshtoken", { "token": localStorage.getItem('id_token') }, null, false)
    .then(response401 => {
      if (response401.success) {
        localStorage.setItem('id_token', response401.data.token);
        localStorage.setItem('user', response401.data.user);
        console.log("Token was expired. We refreshed the token!");
        //We are re-sent the actual request.
        return base(method, url, data, endpointUrl, isFile);
      } else {
        //remove the token from local!.
        //redirect to signin
        return { success: false, status: response401.status, message: "You are not authorized. Please sign in again!" };
      }
    })
    .catch(error => {
      return { success: false, status: error.status, message: "Some error happened!" };
    });
}


export default SuperFetch;
