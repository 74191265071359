// import fakeData from './data';
import companyActions from './actions';

const colors = ['#7ED321', '#de1b1b', '#511E78', '#ff9009', '#42a5f5'];
// const companies = new fakeData(5, colors.length).getAll();

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  companies: [],
  companiesCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 20,
    search: ''
  },
  company: {
    phoneCode:"+1"
  }
};

export default function companyReducer(state = initState, action) {
  //const companies = [...action.companies ?? []];
  // const company = {};
  switch (action.type) {
    case companyActions.CREATE_COMPANY:
      initState.company = {}
      return {
        ...state,
        companies: action.companies,
        company: initState.company
      };
    case companyActions.UPDATE_COMPANY:
      var companyIndex = state.companies.findIndex(u => u.id === action.company.id);
      var companies = state.companies;
      companies[companyIndex] = action.company;
      return {
        ...state,
        companies
      };
    case companyActions.EDIT_COMPANY:
      return {
        ...state,
        company: action.company,
      };
    case companyActions.GET_COMPANY_LIST:
      return {
        ...state,
        companies: action.companies,
        companiesCount: action.companiesCount,
      };
    case companyActions.DELETE_COMPANY:
      return {
        ...state,
        companies: action.companies,
      };
    case companyActions.COMPANY_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        company: action.company === null ? initState.company : action.company,
      };
    case companyActions.CHANGE_COMPANY_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case companyActions.CHANGE_COMPANY_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
