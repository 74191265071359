import actions from './action';
//import {fakeData} from './exampleData'

const initState = {
  totalWebsiteVisitor: 0,
  totalActiveCompany:5,
  totalActiveUser: 5,
  totalCompany: 5,
  totalUser: 5,
  Analyctics: []
};

export default function adminReducer(state = initState, action) {
  //console.log(action.type);

  switch (action.type) {
    case actions.CURRENT_ADMIN:
      return {
        ...state,
        ...action.Admin,
      };
    case actions.UPDATE_ADMIN:
      return {
        ...state,
        totalWebsiteVisitor: action.Admin,
      };
    case actions.SET_CURRENT_ADMIN:
      return {
        ...state,
        totalWebsiteVisitor: action.Admin,
      };
    case actions.GET_ANALYTİCS:
      return {
        ...state,
        Analyctics: action.Analyctics,
      };
    default:
      return state;
  }
  return state;
}
