// import fakeData from './data';
import userActions from './actions';

const colors = ['#7ED321', '#de1b1b', '#511E78', '#ff9009', '#42a5f5'];
// const users = new fakeData(5, colors.length).getAll();

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  users: [],
  usersCount: 0,
  pagination: {
    currentPage: 1,
    skip: 0,
    take: 20,
    search: ''
  },
  user: {
  }
};

export default function userReducer(state = initState, action) {
  //const users = [...action.users ?? []];
  // const user = {};
  switch (action.type) {
    case userActions.CREATE_USER:
      initState.user = {}
      return {
        ...state,
        users: action.users,
        user: initState.user
      };
    case userActions.UPDATE_USER:
      var userIndex = state.users.findIndex(u => u.id === action.user.id);
      var users = state.users;
      users[userIndex] = action.user;
      return {
        ...state,
        users
      };
    case userActions.EDIT_USER:
      return {
        ...state,
        user: action.user,
      };
    case userActions.GET_USER_LIST:
      return {
        ...state,
        users: action.users,
        usersCount: action.usersCount,
      };
    case userActions.DELETE_USER:
      return {
        ...state,
        users: action.users,
      };
    case userActions.USER_TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        user: action.user === null ? initState.user : action.user,
      };
    case userActions.CHANGE_USER_PAGINATION:
      return {
        ...state,
        pagination: { ...action.pagination },
      };
    case userActions.CHANGE_USER_COUNT:
      return {
        ...state,
        productsCount: action.productsCount,
      };
    default:
      return state;
  }
}
