const companyActions = {
    CREATE_COMPANY: 'CREATE_COMPANY',
    UPDATE_COMPANY: 'UPDATE_COMPANY',
    GET_COMPANY: 'GET_COMPANY',
    GET_COMPANY_LIST: 'GET_COMPANY_LIST',
    EDIT_COMPANY: 'EDIT_COMPANY',
    DELETE_COMPANY: 'DELETE_COMPANY',
    COMPANY_TOGGLE_HANDLE_MODAL: 'COMPANY_TOGGLE_HANDLE_MODAL',
    CHANGE_COMPANY_PAGINATION: 'CHANGE_COMPANY_PAGINATION',
    CHANGE_COMPANY_COUNT: 'CHANGE_COMPANY_COUNT',
    createCompany: company => {
      company.clientIds = [];
      company.roleIds = [];
      company.roleGroupIds = [];
      return (dispatch, getState) => {
        dispatch({
          type: companyActions.CREATE_COMPANY,
          companies: [company, ...getState().company.companies],
        });
      };
    },
    updateCompany: company => {
      return (dispatch, getState) => {
        dispatch({
          type: companyActions.UPDATE_COMPANY,
          company,
        });
      };
    },
    editCompany: company => {
      return (dispatch, getState) => {
        dispatch({
          type: companyActions.EDIT_COMPANY,
          company,
        });
      };
    },
    getCompanyList: (companies, companiesCount) => {
      return (dispatch, getState) => {
        dispatch({
          type: companyActions.GET_COMPANY_LIST,
          companies,
          companiesCount
        });
      };
    },
    deleteCompany: id => {
      return (dispatch, getState) => {
        var currentCompanies = getState().company.companies.filter(s => s.id !== id);
        dispatch({
          type: companyActions.DELETE_COMPANY,
          companies: currentCompanies,
        });
      };
    },
    toggleModal: (company = null) => ({
      type: companyActions.COMPANY_TOGGLE_HANDLE_MODAL,
      company,
    }),
    changePagination: pagination => {
      return (dispatch, getState) => {
        dispatch({
          type: companyActions.CHANGE_COMPANY_PAGINATION,
          pagination,
        });
      };
    },
    updateCompaniesCount: companiesCount => {
      return (dispatch, getState) => {
        dispatch({
          type: companyActions.CHANGE_COMPANY_COUNT,
          companiesCount,
        });
      };
    }
  
  };
  export default companyActions;