import Admin from "../../containers/Pages/Admin/Admin";

const actions = {
  CURRENT_ADMIN: 'CURRENT_ADMIN',
  UPDATE_ADMIN:'UPDATE_ADMIN',
  SET_CURRENT_ADMIN:'SET_CURRENT_ADMIN',
  GET_ANALYTİCS:'GET_ANALYTİCS',
  // getCurrentAdmin: Admin =>{
  //   console.log(Admin)
  //   return(dispatch)=>{
  //     console.log("asd");
  //     dispatch({
  //       type: actions.CURRENT_ADMIN,
  //       Admin : AdminData
  //     });
  //   }
  // }
  getAnalytics: Analyctics => (console.log("Analyctics"),{
    type : actions.GET_ANALYTİCS,
    Analyctics 
  }),

  getCurrentAdmin: Admin => ({
    type: actions.CURRENT_ADMIN,
    Admin,
  }),
  updateAnaltycsTraffic: Admin =>(console.log(Admin),{
    type: actions.UPDATE_ADMIN,
    Admin,
  }),
  setCurrentAdmin: () => {
    return (dispatch, getState) => {
      const Admin = getState().Admin;
      dispatch({
        type: actions.SET_CURRENT_ADMIN,
        Admin,
      });
    };
    // type: actions.SET_CURRENT_ADMIN,
    // Admin :  getState().Admin,
  },
};
export default actions;