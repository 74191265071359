import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';

import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import SuperFetch from '../../library/helpers/superFetch';
import jwtConfig from '../../config/jwt.config';

const history = createBrowserHistory();
const fakeApiCall = true; // auth0 or express JWT
const apiCall = true;

const loginRequestMethod = async (fields) =>
  await SuperFetch.post("/auth/token", fields, jwtConfig.fetchUrl)
    .then(response => response)
    .catch(error => error);

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({ payload }) {
    const { token, fields } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        profile: 'Profile',
      });
    }
    else if (apiCall) {
      const response = yield call(loginRequestMethod, fields);
      //
      if (response === undefined) {
        yield put({ type: actions.LOGIN_ERROR, payload: { message: "Something is wrong, please try again later." } });
      } else if (response.success) {
        console.log(response);

        yield put({
          type: actions.LOGIN_SUCCESS,
          token: response.data.token,
          user: response.data.user,
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR, payload: { message: response.message ?? "Unknown error!" } });
      }
    }
    else {
      if (fakeApiCall) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          token: 'secret token',
          profile: 'Profile',
        });
      } else {
        yield put({ type: actions.LOGIN_ERROR });
      }
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () { });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield clearToken();
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get('idToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
